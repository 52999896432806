import { MemoryRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './main.scss';
import InputPanel from './components/InputPanel';
import useSapphirePortalComm, { ISapphirePortalComm } from './comm/SapphirePortalComm';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faDisplay, faArrowRight, faWrench, faCircleNotch,
  faUsersCog, faCube, faSlidersH, faCloud, faDatabase, faEllipsisV, faServer, faHdd, faAngleDown, faAngleRight,
  faUndoAlt, faUserCircle, faSort, faLink, faEdit, faTrash, faCog, faMapMarkerAlt, faUsers, faChalkboardUser, faDoorOpen,
} from '@fortawesome/free-solid-svg-icons';
import SharingPanel from './components/SharingPanel';
import { useEffect, useState } from 'react';
import useWebRTCCaptureMrg from './webrtc/WebRTCCaptureMrg';
import UserfulPageLayout from './layout/UserfulPageLayout';

library.add(faDisplay, faArrowRight, faWrench, faUsersCog, faCube, faSlidersH, faCloud, faDatabase, faEllipsisV, faServer, faHdd, faDoorOpen,
  faAngleDown, faAngleRight, faUndoAlt, faUserCircle, faSort, faLink, faEdit, faTrash, faCircleNotch, faMapMarkerAlt, faCog, faUsers, faChalkboardUser);



interface IProps {
}

export default function App(props:IProps) {
  const [pairingCode, setPairingCode] = useState("");
  const [guestName, setGuestName] = useState("");

  const sapphirePortalComm:ISapphirePortalComm = useSapphirePortalComm({
    onOpen:() => {
      sapphirePortalComm.registerClient();
    }
  }
  );

  const {startCasting, endCasting, isCasting, muteSound, isMuted} = useWebRTCCaptureMrg(pairingCode, guestName.length >= 10 ? guestName.substring(0, 10) + "..." : guestName, sapphirePortalComm);
  
  useEffect(() => {
    console.log("isCasting=" + isCasting + ";pairingCode=" + pairingCode);
    sapphirePortalComm.updateCastingMeetingRoom(isCasting, pairingCode, isCasting ? guestName : "");
  },[isCasting]);

  return (
    <div className="App">
      <UserfulPageLayout className="main-container" showHelp showWindowMax version="12.2">
        <Router>
          <Routes>
            <Route path="/" element={<InputPanel 
                pairingCode={pairingCode} 
                setPairingCode={setPairingCode} 
                guestName={guestName}
                setGuestName={setGuestName}
                isMeetingRoomAvailable={sapphirePortalComm.isMeetingRoomAvailable}
                isMeetingRoomOccupied={sapphirePortalComm.isMeetingRoomOccupied}
                startCasting={startCasting}
                isCasting={isCasting}/>
            } />

            <Route path="/casting" element={
                <SharingPanel pairingCode={pairingCode} endCasting={endCasting} isCasting={isCasting} muteSound={muteSound} isMuted={isMuted}/>
              } />
          </Routes>        
        </Router>
      </UserfulPageLayout>
    </div>
  );
  
}
